var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platform-check" },
    [
      _c(
        "el-table",
        {
          staticClass: "tableCheck",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: "48vh" }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "60", label: "序号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "查岗时间", width: "160" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              "show-overflow-tooltip": "",
              label: "所属公司"
            }
          }),
          _c("el-table-column", { attrs: { prop: "question", label: "问题" } }),
          _c("el-table-column", {
            attrs: { prop: "restore", label: "回复内容" }
          }),
          _c("el-table-column", {
            attrs: { prop: "systems", label: "操作", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    !scope.row.restore
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.responseQuestion(scope.row)
                              }
                            }
                          },
                          [_vm._v("回复")]
                        )
                      : _c("span", [_vm._v("-")])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.diaVisble
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "回复抽查",
                visible: _vm.diaVisble,
                width: "30%",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.diaVisble = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form dialog-form",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "90px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "抽查回复", prop: "restore" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text", placeholder: "请回答" },
                        model: {
                          value: _vm.ruleForm.restore,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "restore",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.restore"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.diaVisble = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }