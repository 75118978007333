<template>
  <div>
    <div class="order-helper" @click="showForm">
      <i class="iconfont its_ba_baobiaofenxi"></i>
      模拟上线
    </div>
    <el-dialog
      title="模拟上线"
      width="50%"
      class="order-dialog home-order-dialog"
      :modal-append-to-body="true"
      :visible.sync="visible"
      :modal="false"
      @closed="closeDia"
    >
      <el-form ref="form" label-width="90px" :model="ruleForm" :rules="rules">
        <div class="scoll-container">
          <el-form-item label="选择车辆：" prop="vehicleNos">
            <el-select
              ref="select_element"
              v-model="value"
              clearable
              filterable
              :filter-method="filterVeh"
              @change="handleSelect"
              placeholder="请输入"
              :loading="loading"
              value-key="vehicleNo"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.cph"
                :value="item[label]"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <!-- 过滤出来的数据展示 -->
      <div class="table-list">
        <el-table :data="tableData" border stripe :height="tableHeight">
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column prop="cph" label="车牌号"></el-table-column>
          <el-table-column
            prop="vehicleNo"
            label="车架号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <!-- <el-table-column
            prop="deviceNo"
            label="设备号"
            :show-overflow-tooltip="true"
          ></el-table-column> -->
          <el-table-column label="操作" width="60px">
            <template slot-scope="scope">
              <el-button
                @click="changeVehicleStatus(scope.$index)"
                type="text"
                size="small"
                style="color: #007aff"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer">
        <el-button @click="submit" type="primary" :disabled="submiting"
          >提交</el-button
        >
        <el-button @click="closeDia">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  simulateVehicleOnline,
  getSimulateVehicle,
} from "@/api/lib/api.js";
export default {
  data () {
    return {
      label: "vehicleNo",
      visible: false,
      ruleForm: {},
      rules: {

      },
      tableHeight: "300px",
      tableData: [],
      submiting: false,
      vehDisabled: false,
      value: null, // 初始值
      options: [],
      loading: false,
      deviceNoList: [],
    };
  },
  created () { },
  mounted () { },
  methods: {
    changeVehicleStatus (index) {
      this.deviceNoList.splice(index);
      this.tableData.splice(index);
    },
    handleSelect () {
      let value = null;
      value = this.options.filter((res) => res[this.label] == this.value);
      if (!this.deviceNoList.includes(this.value)) {
        this.value && this.deviceNoList.unshift(this.value);//去重
        value && value[0] && this.tableData.unshift(value[0]);
      }
    },
    // 获取车辆数据
    filterVeh (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { cph: query }
          getSimulateVehicle(data).then((res) => {
            if (res.code === 1000) {
              this.options = res.data
            }
          })
        }, 200)
      } else {
        this.options = []
      }
    },
    closeDia () {
      this.visible = false;
      this.ruleForm = {};
      this.deviceNoList = [];
      this.tableData = [];
    },
    showForm () {
      this.visible = true;
      this.$nextTick(() => {
        this.ruleForm = {};
        this.deviceNoList = []; 
        this.tableData = [];
        this.options = [];
        this.value = null;
      });
    },
    // 提交工单
    submit () {
        if (
          this.deviceNoList.length > 0
        ) {
          simulateVehicleOnline({vehicleNos:this.deviceNoList})
            .then((res) => {
              if (res.code === 1000) {
                this.$message.success("模拟上线已提交");
                this.visible = false;
              } else {
                this.$message.error(res.msg ? res.msg : "模拟上线提交失败");
              }
              this.submiting = false;
            })
            .catch((err) => {
              this.$message.error("模拟上线提交失败 " + err?.msg ? err.msg : "");
              this.submiting = false;
            });
        } else {
          this.submiting = false;
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-helper {
  background: #fff;
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: #176fd422;
    color: #007aff;
    // border:1px solid #007aff;
  }
}
.order-dialog {
  /deep/.el-dialog {
    border-radius: 15px;
    box-shadow: 0 5px 5px 4px #aaa8;
    .el-dialog__header {
      border: none !important;
    }
  }
}
.home-order-dialog {
  /deep/.el-dialog {
    margin: 0 !important;
    position: absolute;
    right: 20px;
    top: 70px;
  }
  /deep/.el-table:not(.no-blank) .el-table__empty-block .el-table__empty-text {
    height: 100px !important;
  }
}
</style>