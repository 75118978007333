<template>
  <div>
    <div class="trace-complement" @click="showForm">
      <i class="iconfont its_sm_luxian"></i>
      轨迹补传
    </div>
    <el-dialog
      title="轨迹补传"
      width="30%"
      class="order-dialog home-order-dialog"
      :modal-append-to-body="true"
      :visible.sync="visible"
      :modal="false"
      @closed="closeDia"
    >
      <el-form
        ref="form"
        label-width="90px"
        :model="ruleForm"
        :rules="rules"
        class="demo-form-inline"
        :inline="true"
      >
        <el-form-item label="开始时间：" prop="beginTime">
          <el-date-picker
            v-model="ruleForm.beginTime"
            type="datetime"
            placeholder="选择开始时间"
            :clearable="false"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="endTime">
          <el-date-picker
            v-model="ruleForm.endTime"
            type="datetime"
            placeholder="选择结束时间"
            :clearable="false"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="补传平台：" prop="platformList">
          <el-select
              clearable
              v-model="ruleForm.platformList"
              placeholder="请选择"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in platformList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="submit" type="primary" :disabled="submiting"
          >提交</el-button
        >
        <el-button @click="closeDia">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  travelPatch,
} from "@/api/lib/api.js";
import {
  getStartDate,
  getCurentDate,
  checkLimitDate,
  formatDate,
} from '@/common/utils/index'
export default {
  data () {
    return {
      visible: false,
      ruleForm: {
        beginTime: new Date(getStartDate()),
        endTime: new Date(getCurentDate()),
        platformList:[]
      },
      rules: {
        beginTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          // { validator: validateDateForward, trigger: 'blur' },
          // { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          // { validator: validateDateForward, trigger: 'blur' },
          // { validator: validateDateTime2, trigger: 'blur' }
        ],
        platformList:[
        { required: true, message: '请选择', trigger: 'blur' },
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      submiting: false,
      platformList:[
      {
          dictValue: '主防平台',
          dictCode: 'prod'
        },
        {
          dictValue: '省考核平台',
          dictCode: 'prod-nk'
        },
      ]
    };
  },
  created () { },
  mounted () { },
  methods: {

    closeDia () {
      this.visible = false;
      this.$refs?.form?.resetFields();
    },
    showForm () {
      this.visible = true;
      this.$refs?.form?.resetFields();
    },
    // 提交工单
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {...this.ruleForm};
          console.log("this.form",this.ruleForm);
          data.beginTime = formatDate(this.ruleForm.beginTime)
          data.endTime = formatDate(this.ruleForm.endTime)
          this.submiting = true;
          travelPatch(data)
            .then((res) => {
              if (res.code === 1000) {
                this.$message.success("已提交");
                this.visible = false;
              } else {
                this.$message.error(res.msg ? res.msg : "提交失败");
              }
              this.submiting = false;
            })
            .catch((err) => {
              this.$message.error("轨迹补传提交失败 " + err?.msg ? err.msg : "");
              this.submiting = false;
            });
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.trace-complement {
  background: #fff;
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: #176fd422;
    color: #007aff;
    // border:1px solid #007aff;
  }
}
.order-dialog {
  /deep/.el-dialog {
    border-radius: 15px;
    box-shadow: 0 5px 5px 4px #aaa8;
    .el-dialog__header {
      border: none !important;
    }
  }
}
.home-order-dialog {
  /deep/.el-dialog {
    margin: 0 !important;
    position: absolute;
    right: 20px;
    top: 70px;
  }
  /deep/.el-table:not(.no-blank) .el-table__empty-block .el-table__empty-text {
    height: 100px !important;
  }
}
</style>