<template>
  <!-- 平台抽查答复 -->
  <div class="platform-check">
    <el-table
      class="tableCheck"
      :data="tableData"
      style="width: 100%"
      height="48vh"
    >
      <el-table-column type="index" width="60" label="序号"></el-table-column>
      <el-table-column
        prop="lineName"
        label="线路名称"
        width="160"
      ></el-table-column>
      <el-table-column prop="lineType" label="线路类型">
        <template slot-scope="scope">
          <span>
            {{lineTypeList.find(item=>item.dictCode==scope.row.lineType).dictValue}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="operateName" label="方案名称"></el-table-column>
      <el-table-column prop="planStartTime" label="方案执行时间">
        <template slot-scope="scope">
          <span>
            {{scope.row.planStartTime.substring(0,10)}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="planEndTime" label="方案结束时间">
        <template slot-scope="scope">
          <span>
            {{scope.row.planEndTime.substring(0,10)}}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

import { querylistMyAPI } from '@/api/lib/bus-api.js';
export default {
  data () {
    return {
      tableData: [],     
      lineTypeList: [
        { dictValue: "单向环线", dictCode: 1 },
        { dictValue: "双向线路", dictCode: 2 },
        { dictValue: "双向环线", dictCode: 3 }
      ], 
    }
  },
  mounted () {
    this.queryData();
  },
  methods: {
    queryData () {
      querylistMyAPI().then(res => {
        if (res.code === 1000) {
          console.log("-------------", res);
          this.tableData = res.data || [];
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.platform-check {
}
</style>