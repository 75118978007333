<template>
  <div class="header">
    <div class="header-left">
      <i
        class="iconfont"
        :class="isCollapse ? 'its_caidanzhankai' : 'its_caidanguanbi'"
        @click="changeCollapse"
      ></i>
      <i class="iconfont its_home" @click="toHomePage"></i>
    </div>

    <div class="header-center">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">{{
          item.meta.title
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 监控大屏 -->
    <div class="header-order" v-if="$store.state.menu.layoutBtns['largeScreen'] >= 0">
      <div class="large-screen" @click="showLargeScreen">
        <i class="iconfont its_jiankongdaping"></i>
        监控大屏
      </div>
    </div>
    <!-- 轨迹补传 -->
    <div class="header-order" v-if="$store.state.menu.layoutBtns['track'] >= 0">
      <trace-complement></trace-complement>
    </div>
    <!-- 模拟上线 -->
    <div
      class="header-order"
      v-if="$store.state.menu.layoutBtns['simulatedOnline'] >= 0"
    >
      <simulated-online></simulated-online>
    </div>
    <!-- 工单助手 -->
    <div class="header-order">
      <!-- 全局按钮权限只能跟随系统走，全局平台不能设置按钮权限 数据格式已定 -->
      <order-helper
        :isHome="true"
        v-if="$store.state.menu.layoutBtns['orderHelper'] >= 0"
      />
    </div>

    <div class="header-right">
      <!-- 下载中心 -->
      <header-download></header-download>
      <!-- 车辆报警消息  -->
      <div class="msg" @click="msgDialogShow">
        <i class="iconfont its_tongzhi"></i>
        <div class="num">
          {{
            $store.state.vehicle.globalAlarmArr.length > 99
              ? '99+'
              : $store.state.vehicle.globalAlarmArr.length
          }}
        </div>
      </div>
      <div class="header-user-con">
        <!-- 用户名下拉菜单 -->
        <el-dropdown>
          <!-- 用户头像 -->
          <p class="user-avator">
            <img
              src="../../assets/images/home/header.png"
              alt
              class="header_Img"
              srcset
            />
            <span class="user-name">
              <span>{{ username }}</span>
              <i class="el-icon-more el-icon--right"></i>
            </span>
          </p>
          <el-dropdown-menu slot="dropdown" trigger="click">
            <el-dropdown-item @click.native="toAlarm(true)">
              <i class="iconfont its_baojingdanchuangshezhi"></i>
              报警弹窗设置
            </el-dropdown-item>
            <el-dropdown-item @click.native="toText">
              <i class="iconfont its_tiaoduwenbenshezhi"></i>
              调度文本设置
            </el-dropdown-item>
            <el-dropdown-item @click.native="toUpdate">
              <i class="iconfont its_xiugaimima"></i>
              修改密码
            </el-dropdown-item>
            <!-- <el-dropdown-item @click.native="toHead">
              <i class="iconfont its_xiugaitouxiang"></i>
              修改头像
            </el-dropdown-item>-->
            <el-dropdown-item @click.native="logOut">
              <i class="iconfont its_tuichu"></i>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 报警弹窗设置 -->
    <el-dialog
      title="报警弹窗设置"
      :visible.sync="dialogAlarmVisible"
      width="30%"
    >
      <div class="body">
        <el-form
          :model="alarmForm"
          ref="alarmForm"
          label-width="100px"
          label-position="left"
          class="demo-form dialog-form demo-dialog-form"
        >
          <el-form-item label="车辆上线提醒：" prop="vehicleUp">
            <el-switch v-model="alarmForm.vehicleUp"></el-switch>
          </el-form-item>
          <el-form-item label="车辆下线提醒：" prop="vehicleDown">
            <el-switch v-model="alarmForm.vehicleDown"></el-switch>
          </el-form-item>
          <el-form-item label="全局报警弹窗：" prop="globalWindow">
            <el-switch v-model="alarmForm.globalWindow"></el-switch>
          </el-form-item>
          <el-form-item label="全局报警声音：" prop="globalVoice">
            <el-switch v-model="alarmForm.globalVoice"></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="dialogAlarmVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmAlarm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 调度文本设置 -->
    <el-dialog
      title="调度文本设置"
      :visible.sync="dialogTextVisible"
      width="50%"
    >
      <div class="body">
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="新增模板" name="first">
            <el-form
              :model="textForm"
              :rules="textRules"
              ref="textForm"
              label-width="90px"
              label-position="left"
              class="demo-form dialog-form"
            >
              <el-form-item label="模板名称：" prop="name">
                <el-input
                  type="text"
                  v-model="textForm.name"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="详情：" prop="detail">
                <el-input
                  type="textarea"
                  v-model="textForm.detail"
                  maxlength="255"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="模板列表" name="second">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="name" label="模板名称">
                <template slot-scope="scope">
                  <el-input
                    v-if="isEdit && nowId === scope.row.id"
                    v-model="scope.row.name"
                    placeholder="请输入模板名称"
                  ></el-input>
                  <span v-else>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="detail" label="详情">
                <template slot-scope="scope">
                  <el-input
                    v-if="isEdit && nowId === scope.row.id"
                    v-model="scope.row.detail"
                    placeholder="请输入详情"
                  ></el-input>
                  <span v-else>{{ scope.row.detail }}</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="handleEdit(scope.row)"
                    type="text"
                    size="small"
                    v-if="!isEdit"
                    >编辑</el-button
                  >
                  <el-button
                    @click="handleDel(scope.row)"
                    type="text"
                    size="small"
                    v-if="!isEdit"
                    >删除</el-button
                  >
                  <el-button
                    @click="handleSave(scope.row)"
                    type="text"
                    size="small"
                    v-if="isEdit"
                    >保存</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer" v-if="activeName === 'first'">
        <el-button
          size="small"
          type="default"
          @click="dialogTextVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmText"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改头像 -->
    <el-dialog title="修改头像" :visible.sync="dialogHeadVisible" width="30%">
      <div class="body">
        <p>图片上传</p>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <span
            v-if="imageUrl"
            class="el-upload-action"
            @click.stop="handleRemove()"
          >
            <i class="el-icon-delete"></i>
          </span>
          <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="dialogHeadVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmHead"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <div class="body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="90px"
          label-position="left"
          class="demo-form dialog-form"
        >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input
              type="password"
              v-model.trim="ruleForm.oldPassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              type="password"
              v-model.trim="ruleForm.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              type="password"
              v-model.trim="ruleForm.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="default" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="confirmUpdatePsw('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 消息列表 -->
    <el-dialog
      title="消息列表"
      :visible.sync="dialogMessageVisible"
      v-if="dialogMessageVisible"
      width="50%"
    >
      <div class="body">
        <el-tabs type="border-card" v-model="messageActiveName">
          <el-tab-pane label="报警信息" name="alarm">
            <el-table
              class="tableMsg"
              :data="$store.state.vehicle.globalAlarmArr"
              style="width: 100%"
              height="45vh"
              :cell-class-name="tableCellClassName"
              @cell-click="showDetailFun"
            >
              <el-table-column
                type="index"
                width="60"
                label="序号"
              ></el-table-column>
              <el-table-column
                prop="cph"
                label="车牌号"
                width="120"
              ></el-table-column>
              <el-table-column
                prop="alarmSignTime"
                label="报警时间"
                width="180"
              ></el-table-column>
              <el-table-column prop="alarmLevel" label="报警来源">
                <template slot-scope="scope">{{
                  alarmSourceObj[Number(scope.row.alarmSource)]
                }}</template>
              </el-table-column>
              <el-table-column
                prop="alarmType"
                label="报警类型"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="alarmName">{{
                    typeObj[scope.row.alarmType]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="alarmLevel" label="报警等级" width="80">
                <template slot-scope="scope">{{
                  alarmLevelObj[Number(scope.row.alarmLevel)]
                }}</template>
              </el-table-column>
              <el-table-column prop="upgradeRemind" label="是否升级" width="80">
                <template slot-scope="scope">{{
                  scope.row.upgradeRemind ? '是' : ''
                }}</template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="长时间离线车辆" name="offline">
            <el-table
              class="tableMsg"
              :data="$store.state.vehicle.globalOfflineArr"
              style="width: 100%"
              height="45vh"
              :cell-class-name="tableCellClassNameLong"
              @cell-click="showDetailFunLong"
            >
              <el-table-column
                type="index"
                width="60"
                label="序号"
              ></el-table-column>
              <el-table-column
                prop="companyName"
                label="车队名称"
                width="120"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="cph"
                label="车牌号"
                width="120"
              ></el-table-column>

              <el-table-column
                prop="offlineTime"
                label="离线时间"
                width="180"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="alarmName">{{
                    formatDateTime(scope.row.offlineTime)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="离线地点"
                width="250"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="alarmType"
                label="报警类型"
                width="180"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="alarmName">{{
                    typeObj[scope.row.alarmType]
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- 查岗和督办 -->
          <el-tab-pane label="上级平台指令" name="platform">
            <el-table
              class="tableMsg"
              :data="$store.state.vehicle.globalPlatFormArr"
              style="width: 100%"
              height="45vh"
              :cell-class-name="tableCellClassNamePlat"
            >
              <!-- 福建查岗0x9301：37633,督办0x9401：37889 -->
              <el-table-column
                type="index"
                width="60"
                label="序号"
              ></el-table-column>
              <el-table-column prop="type" label="指令">
                <template slot-scope="scope">
                  <span class="alarmName">{{
                    scope.row.type === 37889 ? '督办' : '查岗'
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop
                label="内容"
                width="340"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="alarmName" v-if="scope.row.type === 37889"
                    >应上级平台报警督办，请及时处理{{
                      scope.row.vehicleNo
                    }}的报警</span
                  >
                  <span v-else-if="scope.row.dataType === '0xEC'">{{
                    scope.row.infoContent
                  }}</span>
                  <span v-else-if="scope.row.dataType === '0xF3'">{{
                    scope.row.infoContent | filterJson
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="warnTime"
                label="报警时间"
                width="160"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column prop="upgradeRemind" label="操作">
                <template slot-scope="scope">
                  <span @click="openDealDia(scope.row)" class="action-platform"
                    >处理</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- 过期提醒 -->
          <el-tab-pane label="过期驾驶员" name="driverOut">
            <driver-expire :drivingTypeObj="drivingTypeObj" />
          </el-tab-pane>
          <el-tab-pane label="过期车辆" name="carOut">
            <car-expire
              :licensePlateColorObj="licensePlateColorObj"
              :typeObj="typeCarObj"
            />
          </el-tab-pane>
          <!-- 平台抽查 -->
          <el-tab-pane label="平台抽查" name="platformCheckName">
            <platform-check
              v-if="messageActiveName == 'platformCheckName'"
            ></platform-check>
          </el-tab-pane>
          <!-- 公交2.0-公交计划表过期提醒 -->
          <el-tab-pane v-if="$route.path.indexOf('projectBus') > -1" label="方案到期" name="planOut">
            <plan-out
              v-if="messageActiveName == 'planOut'"
            ></plan-out>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="dialogMessageVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="dialogMessageVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 详情弹窗组件 -->
    <el-dialog title="报警详情" :visible.sync="detailDialog" width="80%">
      <div class="body">
        <alarm-dialog
          :detailData="alarmItem"
          :typeObj="typeObj"
          :alarmLevelObj="alarmLevelObj"
          :alarmSourceObj="alarmSourceObj"
          :disposeWayList="disposeWayList"
          :alarmConfirmList="alarmConfirmList"
          :disposeTypeObj="disposeTypeObj"
          :disposeWayObj="disposeWayObj"
          ref="alarmDia"
          @cancelDialog="cancelDialog"
          v-if="detailDialog"
        ></alarm-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="default" @click="detailDialog = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="diaTitle"
      class="undealMsg"
      :visible.sync="OnInspectionVisible"
      v-if="OnInspectionVisible"
      @close="() => (OnInspectionVisible = false)"
      width="50%"
    >
      <div class="search-area">
        <div class="dialog-area">
          <div class="dialog-header">问题如下</div>
          <div class="dialog-body">
            <img
              v-if="
                superiorPlatformData.infoContent &&
                (superiorPlatformData.infoContent.Type == '验证码' ||
                  superiorPlatformData.infoContent.Type == 1)
              "
              style="text-align: center"
              :src="
                'data:image/png;base64,' +
                superiorPlatformData.infoContent.Content
              "
              alt
            />
            <div v-else>
              <span v-if="superiorPlatformData.dataType === '0xF3'">{{
                superiorPlatformData.infoContent.Content
              }}</span>
              <span v-else>{{ superiorPlatformData.infoContent }}</span>
            </div>
          </div>
          <p class="dialog-textarea">
            <span>回答：</span>
            <el-input
              type="textarea"
              v-model="textareaInspection"
              maxlength="30"
              show-word-limit
            ></el-input>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="() => (OnInspectionVisible = false)"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submit(1)"
          >提交</el-button
        >
        <!-- <el-button size="small" type="default" @click="submit(3)">忽略</el-button>
        <span>
          <el-badge :value="tableData.length" class="item" :max="999">
            <el-button size="small" type="default" @click="submit(2)">取消</el-button>
          </el-badge>
          <el-badge :value="tableData.length" class="item" :max="999">
            <el-button size="small" type="primary" @click="submit(1)">提交</el-button>
          </el-badge>
        </span>-->
      </span>
    </el-dialog>
    <el-dialog
      title="报警督办"
      class="undealMsg"
      :visible.sync="overseeVisible"
      v-if="overseeVisible"
      @close="() => (overseeVisible = false)"
      width="50%"
    >
      <div class="search-area">
        <div class="dialog-area">
          <div class="dialog-header">督办信息</div>
          <div class="dialog-body">
            应上级平台报警督办，请及时处理{{
              superiorPlatformData.vehicleNo
            }}的报警
          </div>
          <p class="dialog-textarea">
            <span>处理：</span>
            <el-select v-model="result" clearable placeholder="请选择处理类型">
              <el-option
                v-for="item in modelList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="() => (overseeVisible = false)"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submit(5)"
          >提交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  loginOut,
  getAlarm,
  updateAlarm,
  updatePassword,
  addMessageTemplate,
  editMessageTemplate,
  deleteMessageTemplate,
  queryMessageTemplateList,
  queryDictsByCodes,
} from "@/api/lib/api.js";
import { checkPassword, formatDict, formatDate } from "@/common/utils/index.js";
import md5 from "js-md5";
import download from "./components/download";
import orderHelper from "./components/orderHelper";
import simulatedOnline from "./components/simulatedOnline";
import alarmDialog from "./components/alarmDetailDialog";
import DriverExpire from './components/driverExpire.vue';
import CarExpire from './components/carExpire.vue';
import traceComplement from './components/traceComplement.vue'
import platformCheck from './components/platformCheck.vue';
import planOut from './components/planOut.vue';
let that;
export default {
  components: {
    HeaderDownload: download,
    orderHelper,
    simulatedOnline,
    "alarm-dialog": alarmDialog,
    DriverExpire,
    CarExpire,
    traceComplement,
    platformCheck,
    planOut
  },
  data () {
    that = this;
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePassRepeat = (rule, value, callback) => {
      if (value === this.ruleForm.oldPassword) {
        callback(new Error("新密码不能等于旧密码!"));
      } else {
        callback();
      }
    };
    return {
      total: 0,
      breadList: [],
      nowId: null,
      activeName: "first",
      isEdit: false,
      dialogVisible: false,
      dialogAlarmVisible: false,
      dialogTextVisible: false,
      dialogHeadVisible: false,
      dialogDownVisible: false,
      username: "",
      headerSerach: "",
      imageUrl: "",
      tableData: [],
      msgData: [], //车辆报警数据
      alarmForm: {
        id: null,
        vehicleUp: null,
        vehicleDown: null,
        globalWindow: null,
        globalVoice: null,
      },
      textForm: {
        name: null,
        detail: null,
      },
      ruleForm: {
        oldPassword: "",
        password: "",
        checkPass: "",
        userId: "",
      },

      isCollapse: false,
      textRules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        detail: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
        ],
      },
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkPassword, trigger: "blur" },
          { validator: validatePassRepeat, trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "change" },
        ],
        checkPass: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      dialogMessageVisible: false,
      typeObj: null,
      alarmLevelObj: null,
      alarmSourceObj: null,
      alarmConfirmList: [],
      disposeWayList: [],
      disposeWayObj: null,
      disposeTypeObj: null,
      detailDialog: false,
      alarmItem: null,

      messageActiveName: "alarm",
      //查岗和督办
      OnInspectionVisible: false, //查岗
      overseeVisible: false, //督办
      superiorPlatformData: null, //上级平台查岗和督办当前展示数据
      modelList: [
        {
          dictValue: "处理中",
          dictCode: 0x00,
        },
        {
          dictValue: "已处理完毕",
          dictCode: 0x01,
        },
        {
          dictValue: "不作处理",
          dictCode: 0x02,
        },
        {
          dictValue: "将来处理",
          dictCode: 0x03,
        },
      ], //督办处理列表
      result: null, //督办处理结果
      textareaInspection: "", //查岗结果
      diaTitle: "上级平台查岗",
      drivingTypeObj: null,
      licensePlateColorObj: null,
      typeCarObj: null,
      token: sessionStorage.getItem("token") || localStorage.getItem("token"),
    };
  },
  watch: {
    "$store.state.vehicle.globalAlarmArr" (val) {
      console.log("watch报警数据", val);
    },
  },
  filters: {
    filterJson (val) {
      return JSON.parse(val).Type;
    },
  },
  methods: {
    openDealDia (row) {
      /**
       * 判断是查岗还是督办，展示不同的弹窗和内容
       * 查岗0x9301：37633-又分为上下级平台查岗和福建平台查岗
       * 督办0x9401：37889
       */
      if (row.dataType === "0xF6") {
        this.overseeVisible = true;
        this.superiorPlatformData = row;
      } else if (row.dataType === "0xF3") {
        this.diaTitle = "福建平台查岗";
        this.superiorPlatformData = {
          ...row,
          infoContent: JSON.parse(row.infoContent),
        };
        this.OnInspectionVisible = true;
      } else if (row.dataType === "0xEC") {
        this.diaTitle = "上级平台查岗";
        this.superiorPlatformData = row;
        this.OnInspectionVisible = true;
      }
    },
    //查岗和督办结果提交
    submit (index) {
      if (index === 1) {
        //上下级平台查岗
        if (this.superiorPlatformData.dataType === "0xEC") {
          const { infoId, objectId, objectType } = this.superiorPlatformData;
          this.$yhsdp.OnInspectionResponse(
            infoId,
            this.textareaInspection,
            objectId,
            objectType
          );
        } else {
          //福建查岗
          const { infoId, objectId, objectType, msgNo } =
            this.superiorPlatformData;
          const infoContent = {
            Type:
              this.superiorPlatformData.infoContent.Type === "验证码" ||
                this.superiorPlatformData.infoContent.Type == 1
                ? 1
                : 0,
            Content: this.textareaInspection,
            Anser: this.superiorPlatformData.infoContent.Anser,
          };
          this.$yhsdp.OnInspectionResponseFuJian(
            infoId,
            infoContent,
            objectId,
            objectType,
            msgNo
          );
        }
        this.OnInspectionVisible = false;
      } else if (index === 5) {
        //福建平台报警督办
        this.$yhsdp.OnoVerseeResponse(this.superiorPlatformData, this.result);
        this.overseeVisible = false;
      }
      //处理一条就移除一条
      this.$store.dispatch(
        "cutIndexGlobalPlatForm",
        this.superiorPlatformData.index - 1
      );
    },
    formatDateTime (v) {
      return formatDate(v);
    },
    // 关闭dialog
    cancelDialog (res) {
      this.detailDialog = false;
      do {
        if (!res) break;
        if (res.length === 1) {
          this.$message.success(res[0]);
          break;
        }
        res.forEach((item, index) => {
          if (index === 0) {
            this.$message.warning(item);
          } else {
            setTimeout(() => {
              this.$message.error(item);
            }, 200 * index);
          }
        });
      } while (false);
    },
    save () {
      this.$refs.alarmDia.save();
    },
    tableCellClassName ({ row, column, rowIndex, columnIndex }) {
      //注意这里是解构利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    tableCellClassNameLong ({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    tableCellClassNamePlat ({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    //查看报警详情弹窗
    showDetailFun (row) {
      //打开详情弹窗，并且消息数量-1
      this.alarmItem = row;
      this.alarmItem.time = row.alarmSignTime;
      this.$store.dispatch("cutIndexGlobalAlarm", row.index - 1);
      this.detailDialog = true;
    },
    //查看长时间离线车辆
    showDetailFunLong (row) {
      //打开详情弹窗，并且消息数量-1
      this.$store.dispatch("cutIndexGlobalLongOffline", row.index - 1);
    },
    getDicts () {
      queryDictsByCodes({
        parentCodes: "BJDJ,D0008,D0009,D0010,BJLY,CLLX,BJCLFS,BJQR,JSZLX,CPYS,VETY",
      }).then((res) => {
        if (res.code === 1000) {
          this.disposeWayList = res.data.BJCLFS;
          this.alarmConfirmList = res.data.BJQR;
          this.disposeWayObj = formatDict(res.data.BJCLFS);
          this.alarmLevelObj = formatDict(res.data.BJDJ);
          this.alarmSourceObj = formatDict(res.data.BJLY);
          this.disposeTypeObj = formatDict(res.data.CLLX);
          this.typeObj = formatDict(
            [].concat(res.data.D0008, res.data.D0009, res.data.D0010)
          );
          this.drivingTypeObj = formatDict(res.data.JSZLX);
          this.licensePlateColorObj = formatDict(res.data.CPYS);
          this.typeCarObj = formatDict(res.data.VETY);
        }
      });
    },
    msgDialogShow () {
      this.dialogMessageVisible = true;
    },
    logOut () {
      this.$confirm("确认退出登录？", "提示")
        .then((_) => {
          this.confirmLogout();
        })
        .catch((_) => { });
    },
    confirmLogout () {
      loginOut({
        token: this.token,
      })
        .then((res) => {
          if (res.code === 1000) {
            localStorage.removeItem("vuex");
            this.$store.dispatch("clear_all");
            this.$store.dispatch("clear_MenuCtr");
            this.$store.dispatch("clear_user");
            this.$store.dispatch("clear_Menus");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("realname");
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            localStorage.removeItem("realname");
            localStorage.removeItem("menuList");
            this.$router.push({ path: "/login" });
            this.$yhsdp.CloseSocketLink();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectMenu (v) {
      console.log(v);
    },
    toUpdate () {
      this.dialogVisible = true;
      if (this.$refs.ruleForm !== undefined) {
        this.$refs.ruleForm.resetFields();
      }
    },
    toAlarm (status) {
      getAlarm({
        id: sessionStorage.getItem("userId") || localStorage.getItem("userId"),
      }).then((res) => {
        if (res.code === 1000) {
          if (status) {
            this.alarmForm = res.data;
            this.dialogAlarmVisible = true;
          }
          localStorage.setItem("onlineRemind", res.data.vehicleUp);
          localStorage.setItem("outlineRemind", res.data.vehicleDown);
          localStorage.setItem("showAlarm", res.data.globalWindow);
          localStorage.setItem("showAlarmVoice", res.data.globalVoice);
        } else {
          this.$message({
            type: "error",
            showClose: true,
            message: res.msg,
          });
        }
      });
    },
    toText () {
      queryMessageTemplateList().then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data;
        } else {
          this.$message({
            type: "error",
            showClose: true,
            message: res.msg,
          });
        }
      });
      this.dialogTextVisible = true;
    },
    handleEdit (row) {
      this.nowId = row.id;
      this.isEdit = true;
    },
    handleDel (row) {
      deleteMessageTemplate({ templateId: row.id }).then((res) => {
        if (res.code === 1000) {
          this.$message.success("删除成功");
          this.toText();
        }
      });
    },
    handleSave (row) {
      const data = {
        id: row.id,
        name: row.name,
        detail: row.detail,
      };
      editMessageTemplate(data).then((res) => {
        if (res.code === 1000) {
          this.$message({
            type: "success",
            showClose: true,
            message: "修改成功",
          });
          this.isEdit = false;
          this.toText();
        } else {
          this.$message({
            type: "error",
            showClose: true,
            message: res.msg,
          });
        }
      });
    },
    toHead () {
      this.dialogHeadVisible = true;
    },
    confirmAlarm () {
      updateAlarm(this.alarmForm).then((res) => {
        if (res.code === 1000) {
          this.$message({
            type: "success",
            showClose: true,
            message: "修改成功",
          });
          this.dialogAlarmVisible = false;
          localStorage.setItem("onlineRemind", this.alarmForm.vehicleUp);
          localStorage.setItem("outlineRemind", this.alarmForm.vehicleDown);
          localStorage.setItem("showAlarm", this.alarmForm.globalWindow);
          localStorage.setItem("showAlarmVoice", this.alarmForm.globalVoice);
        } else {
          this.$message({
            type: "error",
            showClose: true,
            message: res.msg,
          });
        }
      });
    },
    confirmText () {
      this.$refs.textForm.validate((valid) => {
        if (valid) {
          addMessageTemplate(this.textForm).then((res) => {
            if (res.code === 1000) {
              (this.textForm = {
                name: null,
                detail: null,
              }),
                this.$message.success("新增成功");
              this.toText();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    confirmHead () { },
    confirmUpdatePsw (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updatedPwd();
        } else {
          return false;
        }
      });
    },

    updatedPwd () {
      const data = {
        oldPassword: md5(this.ruleForm.oldPassword),
        newPassword: md5(this.ruleForm.checkPass),
      };
      updatePassword(data).then((res) => {
        if (res.code === 1000) {
          this.$message.success("修改成功");
          // 退出登录
          localStorage.removeItem("vuex");
          this.$store.commit("clear_all");
          this.$store.commit("clear_MenuCtr");
          this.$store.commit("clear_user");
          this.$store.commit("clear_Menus");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("menuList");

          this.$router.push({ path: "/login" });
          this.$yhsdp.CloseSocketLink();
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    // 移除图片
    handleRemove () {
      this.imageUrl = "";
    },
    // 上传成功回调
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload (file) {
      const type =
        file.type === "image/jpeg" ||
        "image/jpg" ||
        "image/webp" ||
        "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!type) {
        this.$message.error("图片格式不正确!(只能包含jpg，png，webp，JPEG)");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return type && isLt2M;
    },
    onDarkTheme () {
      this.$addStorageEvent(1, "YH_BF_THEME", "theme-dark");
    },
    onLightTheme () {
      this.$addStorageEvent(1, "YH_BF_THEME", "theme-light");
    },
    isHome (route) {
      return route.path.indexOf("dashboard") > -1;
    },
    getBreadcrumb () {
      this.breadList =
        this.$route.matched.filter((item) => item.meta.title) || [];
    },
    changeCollapse () {
      this.isCollapse = !this.isCollapse;
      this.$bus.$emit("isCollapse", this.isCollapse);
    },
    toHomePage () {
      this.$router.push("/platform");
    },
    //查看监控大屏
    showLargeScreen(){
      window.open(`https://datav.aliyuncs.com/share/page/c005be3f224de96b77ff1ae8eb43e57a?token=${this.token}`)
    }
  },
  created () {
    this.username =
      sessionStorage.getItem("realname") || localStorage.getItem("realname");
    this.getBreadcrumb();
    this.toAlarm(false);
    this.getDicts();
  },
  mounted () { },
  watch: {
    $route () {
      this.getBreadcrumb();
    },
    "isRefreshLive.refreshDownLoad" (newvalue) {
      if (newvalue === true) {
        this.getDownloadLength();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-size: 22px;
  display: flex;
  align-items: center;
}
//监控大屏
.large-screen {
  background: #fff;
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: #176fd422;
    color: #007aff;
  }
}
.header-left {
  flex: 1;
  display: flex;
  justify-content: space-around;
  .iconfont {
    font-size: 22px;
  }
}
.header-order {
  // padding: 0 10px;
  border-right: 1px solid #eee;
  margin-right: 30px;
}
.header-center {
  flex: 10;
}
.header-serach {
  flex: 2;
}
.collapse-btn {
  flex: 2;
  padding: 0 21px;
  cursor: pointer;
  line-height: 60px;
}
.header-right {
  // flex: 1;
  display: flex;
  height: 3.5vh;
  align-items: center;
  justify-content: center;
  .header-user-con {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  >>> .el-dropdown {
    color: #fff;
  }
  .user-avator {
    cursor: pointer;
    font-size: 16px;
    color: #606266;
    text-align: right;
    display: flex;
    align-content: center;
    align-items: center;
    & > img {
      height: 3.5vh;
      width: 3.5vh;
      border-radius: 50%;
    }
    & > .user-name {
      height: 3.5vh;
      display: flex;
      align-content: center;
      align-items: center;
      & > span {
        height: 3.5vh;
        line-height: 3.5vh;
        max-width: 5vw;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .el-icon-more {
    margin-left: 20px;
  }
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
/deep/.demo-dialog-form .el-form-item__content {
  line-height: 32px;
  height: 32px;
}

.undealMsg {
  .el-dialog__body {
    padding: 1.5vh;
    .el-badge {
      margin-right: 10px;
    }
    .el-button--default {
      padding: 9px 20px;
    }
    p span {
      margin-right: 20px;
      line-height: 26px;
    }
  }
  .search-area {
    padding: 0;
  }
  .dialog-area {
    padding: 1.5vh 1.5vh 18vh 1.5vh;
  }
  .dialog-header {
    display: flex;
    flex-wrap: nowrap;
    background: #f0faf2;
    justify-content: space-around;
    align-items: center;
    padding: 2vh 0;
  }

  .dialog-body {
    width: 90%;
    margin: 30px auto;
    text-align: center;
  }
}
.action-platform {
  cursor: pointer;
  color: #336fee;
}
.avatar-uploader {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/images/home/header.png') no-repeat;
  background-size: 100% 100%;
}
.avatar-uploader-icon {
  font-size: 0;
  color: #fff;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar-uploader-icon:hover {
  font-size: 28px;
  background-color: rgba(0, 0, 0, 0.3);
}
.avatar {
  position: relative;
  width: 120px;
  height: 120px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 120px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.3);
}
.msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
  margin: 0 24px 0 6px;
  color: #999;
  position: relative;
  i {
    font-size: 22px;
  }
  .num {
    position: absolute;
    top: -4px;
    left: 11px;
    padding: 1px 4px;
    background: #f80b0b;
    color: #fff;
    border-radius: 8px;
  }
}
.tableMsg {
  /deep/.el-table__empty-block .el-table__empty-text {
    height: 200px;
  }
}
</style>
