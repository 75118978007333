var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticClass: "tableCar",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.$store.state.vehicle.expireCarsArr,
            height: "48vh"
          },
          on: { "cell-click": _vm.showDetailFun }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "60", label: "序号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cph", label: "车牌号", width: "110" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              width: "220",
              "show-overflow-tooltip": "",
              label: "所属企业"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "systems", label: "系统标识" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.systems == "0"
                            ? "GPS"
                            : scope.row.systems == "1"
                            ? "出租车"
                            : scope.row.systems == "2"
                            ? "公交车"
                            : "-"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "licensePlateColor", label: "车牌颜色" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.licensePlateColorObj[
                            Number(scope.row.licensePlateColor)
                          ]
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "车辆类型",
              width: "170",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.typeObj[Number(scope.row.type)]))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "70%",
                "custom-class": "add-vehicle-dialog",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("add-vehicle", {
                ref: "addVehicle",
                attrs: { vehicleNo: _vm.vehicleNo, rowData: _vm.rowData },
                on: { saveEnd: _vm.saveEnd, onLoading: _vm.onLoading }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }