<template>
  <div>
    <el-table
      class="tableCar"
      :data="$store.state.vehicle.expireCarsArr"
      style="width: 100%"
      height="48vh"
      @cell-click="showDetailFun"
    >
      <el-table-column type="index" width="60" label="序号"></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="110"></el-table-column>
      <el-table-column
        prop="companyName"
        width="220"
        show-overflow-tooltip
        label="所属企业"
      ></el-table-column>
      <el-table-column prop="systems" label="系统标识">
        <template slot-scope="scope">
          <span>{{
            scope.row.systems == '0'
              ? 'GPS'
              : scope.row.systems == '1'
              ? '出租车'
              : scope.row.systems == '2'
              ? '公交车'
              : '-'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="licensePlateColor" label="车牌颜色">
        <template slot-scope="scope">
          <span>{{
            licensePlateColorObj[Number(scope.row.licensePlateColor)]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="车辆类型" width="170" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{
            typeObj[Number(scope.row.type)]
          }}</span>
        </template>        
      </el-table-column>
    </el-table>
    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="70%"
      custom-class="add-vehicle-dialog"
      :append-to-body="true"
    >
      <add-vehicle
        ref="addVehicle"
        :vehicleNo="vehicleNo"
        :rowData="rowData"
        @saveEnd="saveEnd"
        @onLoading="onLoading"
      />

      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" size="small" :loading="editLoading" @click="onSave">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryVehicleCertificateExpire } from "@/api/lib/api.js";
import addVehicle from "@/views/projectResourse/basicData/vehicleManagement/components/addVehicle.vue";
export default {
  name: 'Avatar',
  components:{
    addVehicle
  },
  props:{
    typeObj:{
      type:Object,
      default:()=>{}
    },
    licensePlateColorObj:{
      type:Object,
      default:()=>{}
    },
  },
  computed: {},
  data () {
    return {
      dialogVisible:false,
      vehicleNo:"",
      rowData:null,
      editLoading:false,
      title:"",
    }
  },
  created () {
    
  },
  methods: {
    onLoading (flag) {
      this.editLoading = flag;
    },
    onSave () {
      this.$refs.addVehicle.onSave();
    },
    saveEnd () {
      this.dialogVisible = false;
      //更新本地存储的过期数据
      queryVehicleCertificateExpire().then(res=>{
        this.$store.commit('add_expireCarData', res.data)
      })
    },
    showDetailFun (row) {
      this.title = "修改车辆信息";
      this.rowData = { ...row };
      this.vehicleNo = row.vehicleNo;
      this.dialogVisible = true;
    },
  },

  watch: {

  },
  mounted () {
   
  }
}
</script>
<style lang="scss" scoped>
</style>
