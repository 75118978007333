<template>
  <div>
    <el-table
      class="tableDriver"
      :data="$store.state.vehicle.expireDriversArr"
      style="width: 100%"
      height="48vh"
      @cell-click="showDetailFun"
    >
      <el-table-column type="index" width="60" label="序号"></el-table-column>
      <el-table-column prop="driverName" label="驾驶员"></el-table-column>
      <el-table-column
        prop="companyName"
        width="260"
        show-overflow-tooltip
        label="所属企业"
      ></el-table-column>
      <el-table-column prop="drivingType" label="驾驶证类型">
        <template slot-scope="scope">{{drivingTypeObj[Number(scope.row.drivingType)]
        }}</template>
      </el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
    </el-table>
    <!-- 弹窗 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="70%"
      :append-to-body="true"
    >
      <add-driver
        ref="addDriver"
        :companyList="companyList"
        :sexList="sexList"
        :isIDCardEdit="true"
        :starLevelList="starLevelList"
        :drivingTypeList="drivingTypeList"
        :drivingChoiceList="drivingChoiceList"
        :rowData="rowData"
        :title="title"
        @upDateData="upDateData"
        @uploading="uploading"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryDictsByCodes, queryDriverExpire, getBusinessCompanyTree } from "@/api/lib/api.js";
import AddDriver from '@/views/projectResourse/basicData/driverManagement/components/addDriver.vue';
export default {
  name: 'driverExpire',
  components: { AddDriver },
  props:{
    drivingTypeObj:{
      type:Object,
      default:()=>{}
    }
  },
  data () {
    return {
      dialogVisible: false,
      title: "",
      rowData: null,
      editLoading: false,
      companyList: [],
      sexList: [],
      starLevelList: [],
      drivingTypeList: [],
      drivingChoiceList: [],
    }
  },
  methods: {
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "JSZLX,XB,XJ,JSYLX" }).then(res => {
        if (res.code === 1000) {
          this.drivingTypeList = res.data.JSZLX;
          this.sexList = res.data.XB;
          this.starLevelList = res.data.XJ;
          this.drivingChoiceList = res.data.JSYLX;
        }
      });
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then(res => {
        if (res.code === 1000) {
          this.companyList = res.data;
        }
      });
    },
    getTableData () {
      queryDriverExpire().then(res => {
        this.$store.commit('add_expireDriverData', res.data)
      })
    },
    showDetailFun (row) {
      this.title = "修改驾驶员信息";
      this.rowData = {
        id: row.id,
        companyName: row.companyName
      }
      this.dialogVisible = true;
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.dialogVisible = false;
      this.$refs.addDriver.onClose();
      this.rowData = null;
    },
    upDateData () {
      this.onDialogClose();
      this.getTableData();
    },
    uploading (flag) {
      this.editLoading = flag;
    },
    // 新增/修改驾驶员
    onSave () {
      this.$refs.addDriver.onSave();
    },
  },
  mounted () { },
  created () {
    this.getCompanyList();
    this.getDicts();
  }
}
</script>
<style lang="scss" scoped>
</style>
