<template>
  <div class="sideBar" :style="isCollapse ? 'width:60px' : ''">
    <div class="logo">
      <h3 class="title">
        <i
          :style="{
            'background-image': 'url(' + activeSystem.icon + ')',
          }"
        ></i>
        <span v-if="!isCollapse">{{ activeSystem.name }}</span>
      </h3>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      @select="handleSelect"
      :collapse="isCollapse"
      :collapse-transition="false"
      :unique-opened="true"
      background-color="#242733"
      text-color="#82918F"
      active-text-color="#fff"
    >
      <template v-for="(item, index) in menuList">
        <el-submenu
          :index="item.url"
          :key="index"
          v-if="item.subMenuTrees && item.subMenuTrees[0].type == 1"
        >
          <template slot="title">
            <i class="iconfont" :class="item.icon" />
            <span slot="title">{{ item.menuName }}</span>
          </template>
          <el-menu-item
            v-for="(sub, i) in item.subMenuTrees"
            :index="path + '/' + sub.url"
            :key="i"
            >{{ sub.menuName }}</el-menu-item
          >
        </el-submenu>
        <!-- type为2的是全局按钮权限 -->
        <el-menu-item v-else-if="item.type==1" :index="path + '/' + item.url" :key="index">
          <i class="iconfont" :class="item.icon" />
          <span slot="title">{{ item.menuName }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      menuList: [],
      path: "",
      activeIndex: "projectBus/dashboard",
      systemList: {
        projectGps: {
          name: "世畅纬佳车辆监控平台",
          icon: require("@/assets/images/home/home1.png"),
          url: "gps",
        },
        projectBus: {
          name: "公交主动安全智能调度",
          icon: require("@/assets/images/home/home2.png"),
          url: "bus",
        },
        projectTaxi: {
          name: "出租车服务平台",
          icon: require("@/assets/images/home/home3.png"),
          url: "taxi",
        },
        projectResourse: {
          name: "资源管理",
          icon: require("@/assets/images/home/home4.png"),
          url: "resourse",
        },
        projectRecord: {
          name: "经营分析",
          icon: require("@/assets/images/home/home5.png"),
          url: "record",
        },
      },
      menu_list:localStorage.getItem("menuList") || sessionStorage.getItem("menuList") || [],
    };
  },
  computed: {
    activeSystem() {
      this.handleAuthority();
      this.path = this.$route.path.split("/")[1].split("/")[0];
      return this.path
        ? this.systemList[this.path]
        : this.systemList.projectGps;
    },
  },
  created() {
    //事件订阅
    this.$bus.$on("isCollapse", (res) => {
      this.isCollapse = res;
    });
    this.activeIndex = window.sessionStorage.getItem("routeName");
    // JSON.parse(sessionStorage.getItem('menuList')).subMenuTrees.forEach(
    //   (element) => {
    //     if (element.url === this.activeSystem.url) {
    //       this.menuList = element.subMenuTrees
    //     }
    //   }
    // )
    /**保持登录状态修复 */
    JSON.parse(localStorage.getItem("menuList") || sessionStorage.getItem("menuList")).subMenuTrees.forEach(
      (element) => {
        if (element.url === this.activeSystem.url) {
          this.menuList = element.subMenuTrees;
        }
      }
    );
    
  },
  watch: {
    $route: function (val) {
      this.activeIndex = val.path.substr(1);
    },
  },
  methods: {
    changeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleAuthority() {
      //微前端处理路由问题
       if (window.__POWERED_BY_QIANKUN__) { 
        this.path = this.$route.path.split("/")[1].split("/")[0];
        const url = this.systemList[this.path].url;
        JSON.parse(this.menu_list).subMenuTrees.forEach((element) => {
          if (element.url === url) {
            this.menuList = element.subMenuTrees;
          }
        });
      }
      // 处理按钮菜单权限
      if (this.menuList && this.menuList.length > 0) {
        let layoutBtns = {};
        this.menuList.forEach((element) => {
          if (element.type == 2) {
            layoutBtns[element.url] = true;
          }
          // 一级菜单
          if (this.path + "/" + element.url === this.activeIndex) {
            var btnMenu = [];
            if (element.subMenuTrees) {
              element.subMenuTrees.forEach((item) => {
                btnMenu.push(item.menuName);
              });
            } else {
              btnMenu.push("###");
            }
            this.$store.dispatch("now_Menus", btnMenu.join());
          } else {
            // 二级菜单
            if (element.subMenuTrees) {
              element.subMenuTrees.forEach((sub) => {
                if (this.path + "/" + sub.url === this.activeIndex) {
                  var btnMenu = [];
                  if (sub.subMenuTrees) {
                    sub.subMenuTrees.forEach((item) => {
                      btnMenu.push(item.menuName);
                    });
                  } else {
                    btnMenu.push("###");
                  }
                  this.$store.dispatch("now_Menus", btnMenu.join());
                }
              });
            }
          }
        });
        this.$store.commit("layout_btns", layoutBtns);
      }
    },
    handleSelect(key, keyPath) {
      this.$router.push({ path: `/${key}` });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  beforeDestroy() {
    this.$bus.$off("isCollapse");
  },
};
</script>

<style lang="scss" scoped>
.sideBar {
  @include themify() {
    .logo {
      padding-left: 16px;
      margin-bottom: 30px;
      .title {
        color: #fff;
        font-size: 16px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: left;
        align-items: center;
        width: 100%;
        i {
          display: inline-block;
          content: "";
          height: 26px;
          width: 26px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 6px;
          border-radius: 50%;
        }
      }
      img {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        margin-right: 8px;
      }
      .system-name {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .el-menu {
      border: none;
    }
    .el-menu--collapse {
      width: 60px;
    }
    .iconfont {
      font-size: 16px;
    }
    .el-submenu .el-menu-item {
      margin-left: 10px;
    }
    .el-menu-vertical-demo span {
      margin-left: 5px;
      font-size: 14px;
    }
    .el-menu-item,
    .el-submenu__title {
      height: 48px !important;
      line-height: 48px !important;
    }
    .el-menu-item i,
    .el-submenu__title i {
      color: inherit;
    }
    .el-submenu__title:hover {
      color: white !important;
      background-color: themed("b3") !important;
    }
    .el-menu-item:hover {
      color: white !important;
      background-color: themed("b3") !important;
    }
    .el-menu-item.is-active {
      background-color: themed("b4") !important;
    }
    .el-submenu.is-active >>> .el-submenu__title i,
    .el-submenu.is-active >>> .el-submenu__title {
      color: white !important;
    }
  }
}
</style>
