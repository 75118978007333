<template>
  <!-- 平台抽查答复 -->
  <div class="platform-check">
    <el-table
      class="tableCheck"
      :data="tableData"
      style="width: 100%"
      height="48vh"
    >
      <el-table-column type="index" width="60" label="序号"></el-table-column>
      <el-table-column
        prop="createTime"
        label="查岗时间"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        show-overflow-tooltip
        label="所属公司"
      ></el-table-column>
      <el-table-column prop="question" label="问题"></el-table-column>
      <el-table-column prop="restore" label="回复内容"></el-table-column>
      <el-table-column prop="systems" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
          v-if="!scope.row.restore"
            @click="responseQuestion(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >回复</el-button
          >
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="回复抽查"
      :visible.sync="diaVisble"
      v-if="diaVisble"
      width="30%"
      append-to-body
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="90px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="抽查回复" prop="restore">
          <el-input
            type="text"
            v-model.trim="ruleForm.restore"
            placeholder="请回答"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="default" @click="diaVisble = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="onSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>

import { queryQuestionMsgListApi, restoreQuestionApi } from '@/api/lib/gps-api.js';
export default {
  data () {
    return {
      tableData: [],
      diaVisble: false,
      ruleForm: {
        restore: ''
      },
      rules: {
        restore: [{ required: true, message: "请输入答复", trigger: "blur" }]
      },
      questionId:null,
    }
  },
  mounted () {
    this.queryData();
  },
  methods: {
    queryData () {
      queryQuestionMsgListApi().then(res => {
        if (res.code === 1000) {
          console.log("-------------", res);
          this.tableData = res.data || [];
        }
      })
    },
    responseQuestion (row) {
      this.diaVisble = true;
      this.questionId = row.id;
    },
    onSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          restoreQuestionApi({
            id:this.questionId,
            restore:this.ruleForm.restore
          }).then(res=>{
            if(res.code===1000){
              this.$message.success(res.msg);
              this.diaVisble = false;
              this.queryData();
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.platform-check {
}
</style>