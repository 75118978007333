var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "order-helper", on: { click: _vm.showForm } }, [
        _c("i", { staticClass: "iconfont its_ba_baobiaofenxi" }),
        _vm._v(" 模拟上线 ")
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "order-dialog home-order-dialog",
          attrs: {
            title: "模拟上线",
            width: "50%",
            "modal-append-to-body": true,
            visible: _vm.visible,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            closed: _vm.closeDia
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "90px",
                model: _vm.ruleForm,
                rules: _vm.rules
              }
            },
            [
              _c(
                "div",
                { staticClass: "scoll-container" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择车辆：", prop: "vehicleNos" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "select_element",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            "filter-method": _vm.filterVeh,
                            placeholder: "请输入",
                            loading: _vm.loading,
                            "value-key": "vehicleNo"
                          },
                          on: { change: _vm.handleSelect },
                          model: {
                            value: _vm.value,
                            callback: function($$v) {
                              _vm.value = $$v
                            },
                            expression: "value"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.cph, value: item[_vm.label] }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "table-list" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    stripe: "",
                    height: _vm.tableHeight
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "60" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cph", label: "车牌号" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vehicleNo",
                      label: "车架号",
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "60px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#007aff" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeVehicleStatus(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.submiting },
                  on: { click: _vm.submit }
                },
                [_vm._v("提交")]
              ),
              _c("el-button", { on: { click: _vm.closeDia } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }