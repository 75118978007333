var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c("div", { staticClass: "header-left" }, [
        _c("i", {
          staticClass: "iconfont",
          class: _vm.isCollapse ? "its_caidanzhankai" : "its_caidanguanbi",
          on: { click: _vm.changeCollapse }
        }),
        _c("i", {
          staticClass: "iconfont its_home",
          on: { click: _vm.toHomePage }
        })
      ]),
      _c(
        "div",
        { staticClass: "header-center" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            _vm._l(_vm.breadList, function(item, index) {
              return _c("el-breadcrumb-item", { key: index }, [
                _vm._v(_vm._s(item.meta.title))
              ])
            }),
            1
          )
        ],
        1
      ),
      _vm.$store.state.menu.layoutBtns["largeScreen"] >= 0
        ? _c("div", { staticClass: "header-order" }, [
            _c(
              "div",
              {
                staticClass: "large-screen",
                on: { click: _vm.showLargeScreen }
              },
              [
                _c("i", { staticClass: "iconfont its_jiankongdaping" }),
                _vm._v(" 监控大屏 ")
              ]
            )
          ])
        : _vm._e(),
      _vm.$store.state.menu.layoutBtns["track"] >= 0
        ? _c(
            "div",
            { staticClass: "header-order" },
            [_c("trace-complement")],
            1
          )
        : _vm._e(),
      _vm.$store.state.menu.layoutBtns["simulatedOnline"] >= 0
        ? _c(
            "div",
            { staticClass: "header-order" },
            [_c("simulated-online")],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "header-order" },
        [
          _vm.$store.state.menu.layoutBtns["orderHelper"] >= 0
            ? _c("order-helper", { attrs: { isHome: true } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "header-right" },
        [
          _c("header-download"),
          _c("div", { staticClass: "msg", on: { click: _vm.msgDialogShow } }, [
            _c("i", { staticClass: "iconfont its_tongzhi" }),
            _c("div", { staticClass: "num" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$store.state.vehicle.globalAlarmArr.length > 99
                      ? "99+"
                      : _vm.$store.state.vehicle.globalAlarmArr.length
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "header-user-con" },
            [
              _c(
                "el-dropdown",
                [
                  _c("p", { staticClass: "user-avator" }, [
                    _c("img", {
                      staticClass: "header_Img",
                      attrs: {
                        src: require("../../assets/images/home/header.png"),
                        alt: "",
                        srcset: ""
                      }
                    }),
                    _c("span", { staticClass: "user-name" }, [
                      _c("span", [_vm._v(_vm._s(_vm.username))]),
                      _c("i", { staticClass: "el-icon-more el-icon--right" })
                    ])
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      attrs: { slot: "dropdown", trigger: "click" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function($event) {
                              return _vm.toAlarm(true)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont its_baojingdanchuangshezhi"
                          }),
                          _vm._v(" 报警弹窗设置 ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function($event) {
                              return _vm.toText($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont its_tiaoduwenbenshezhi"
                          }),
                          _vm._v(" 调度文本设置 ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function($event) {
                              return _vm.toUpdate($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "iconfont its_xiugaimima" }),
                          _vm._v(" 修改密码 ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function($event) {
                              return _vm.logOut($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "iconfont its_tuichu" }),
                          _vm._v(" 退出登录 ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报警弹窗设置",
            visible: _vm.dialogAlarmVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAlarmVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "body" },
            [
              _c(
                "el-form",
                {
                  ref: "alarmForm",
                  staticClass: "demo-form dialog-form demo-dialog-form",
                  attrs: {
                    model: _vm.alarmForm,
                    "label-width": "100px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车辆上线提醒：", prop: "vehicleUp" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.alarmForm.vehicleUp,
                          callback: function($$v) {
                            _vm.$set(_vm.alarmForm, "vehicleUp", $$v)
                          },
                          expression: "alarmForm.vehicleUp"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车辆下线提醒：", prop: "vehicleDown" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.alarmForm.vehicleDown,
                          callback: function($$v) {
                            _vm.$set(_vm.alarmForm, "vehicleDown", $$v)
                          },
                          expression: "alarmForm.vehicleDown"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "全局报警弹窗：", prop: "globalWindow" }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.alarmForm.globalWindow,
                          callback: function($$v) {
                            _vm.$set(_vm.alarmForm, "globalWindow", $$v)
                          },
                          expression: "alarmForm.globalWindow"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "全局报警声音：", prop: "globalVoice" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.alarmForm.globalVoice,
                          callback: function($$v) {
                            _vm.$set(_vm.alarmForm, "globalVoice", $$v)
                          },
                          expression: "alarmForm.globalVoice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "default" },
                  on: {
                    click: function($event) {
                      _vm.dialogAlarmVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmAlarm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "调度文本设置",
            visible: _vm.dialogTextVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTextVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "body" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card" },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "新增模板", name: "first" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "textForm",
                          staticClass: "demo-form dialog-form",
                          attrs: {
                            model: _vm.textForm,
                            rules: _vm.textRules,
                            "label-width": "90px",
                            "label-position": "left"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "模板名称：", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  maxlength: "50",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.textForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.textForm, "name", $$v)
                                  },
                                  expression: "textForm.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "详情：", prop: "detail" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "255",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.textForm.detail,
                                  callback: function($$v) {
                                    _vm.$set(_vm.textForm, "detail", $$v)
                                  },
                                  expression: "textForm.detail"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "模板列表", name: "second" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableData }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "模板名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.isEdit && _vm.nowId === scope.row.id
                                      ? _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入模板名称"
                                          },
                                          model: {
                                            value: scope.row.name,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "name", $$v)
                                            },
                                            expression: "scope.row.name"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.name))
                                        ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "detail", label: "详情" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.isEdit && _vm.nowId === scope.row.id
                                      ? _c("el-input", {
                                          attrs: { placeholder: "请输入详情" },
                                          model: {
                                            value: scope.row.detail,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "detail", $$v)
                                            },
                                            expression: "scope.row.detail"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.detail))
                                        ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              fixed: "right",
                              label: "操作",
                              width: "100"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    !_vm.isEdit
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleEdit(scope.row)
                                              }
                                            }
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      : _vm._e(),
                                    !_vm.isEdit
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleDel(scope.row)
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                    _vm.isEdit
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleSave(scope.row)
                                              }
                                            }
                                          },
                                          [_vm._v("保存")]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.activeName === "first"
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.dialogTextVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.confirmText }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改头像",
            visible: _vm.dialogHeadVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogHeadVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "body" },
            [
              _c("p", [_vm._v("图片上传")]),
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: "https://jsonplaceholder.typicode.com/posts/",
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                    "on-remove": _vm.handleRemove,
                    "before-upload": _vm.beforeAvatarUpload
                  }
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl }
                      })
                    : _vm._e(),
                  _vm.imageUrl
                    ? _c(
                        "span",
                        {
                          staticClass: "el-upload-action",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleRemove()
                            }
                          }
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      )
                    : _c("i", {
                        staticClass: "el-icon-upload2 avatar-uploader-icon",
                        attrs: { stop: "" }
                      })
                ]
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "default" },
                  on: {
                    click: function($event) {
                      _vm.dialogHeadVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmHead }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "body" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form dialog-form",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "90px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "旧密码", prop: "oldPassword" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", autocomplete: "off" },
                        model: {
                          value: _vm.ruleForm.oldPassword,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "oldPassword",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.oldPassword"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "新密码", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", autocomplete: "off" },
                        model: {
                          value: _vm.ruleForm.password,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "确认密码", prop: "checkPass" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", autocomplete: "off" },
                        model: {
                          value: _vm.ruleForm.checkPass,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "checkPass",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.checkPass"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "default" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.confirmUpdatePsw("ruleForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogMessageVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "消息列表",
                visible: _vm.dialogMessageVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogMessageVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "border-card" },
                      model: {
                        value: _vm.messageActiveName,
                        callback: function($$v) {
                          _vm.messageActiveName = $$v
                        },
                        expression: "messageActiveName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "报警信息", name: "alarm" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "tableMsg",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.$store.state.vehicle.globalAlarmArr,
                                height: "45vh",
                                "cell-class-name": _vm.tableCellClassName
                              },
                              on: { "cell-click": _vm.showDetailFun }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "60",
                                  label: "序号"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "cph",
                                  label: "车牌号",
                                  width: "120"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "alarmSignTime",
                                  label: "报警时间",
                                  width: "180"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "alarmLevel",
                                  label: "报警来源"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm.alarmSourceObj[
                                                Number(scope.row.alarmSource)
                                              ]
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4086582494
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "alarmType",
                                  label: "报警类型",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "alarmName" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.typeObj[
                                                    scope.row.alarmType
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3405069638
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "alarmLevel",
                                  label: "报警等级",
                                  width: "80"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm.alarmLevelObj[
                                                Number(scope.row.alarmLevel)
                                              ]
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  624819294
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "upgradeRemind",
                                  label: "是否升级",
                                  width: "80"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.upgradeRemind
                                                ? "是"
                                                : ""
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  232718014
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "长时间离线车辆", name: "offline" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "tableMsg",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.$store.state.vehicle.globalOfflineArr,
                                height: "45vh",
                                "cell-class-name": _vm.tableCellClassNameLong
                              },
                              on: { "cell-click": _vm.showDetailFunLong }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "60",
                                  label: "序号"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "车队名称",
                                  width: "120",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "cph",
                                  label: "车牌号",
                                  width: "120"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "offlineTime",
                                  label: "离线时间",
                                  width: "180",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "alarmName" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDateTime(
                                                    scope.row.offlineTime
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  833825731
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "address",
                                  label: "离线地点",
                                  width: "250",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "alarmType",
                                  label: "报警类型",
                                  width: "180",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "alarmName" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.typeObj[
                                                    scope.row.alarmType
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3405069638
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "上级平台指令", name: "platform" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "tableMsg",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data:
                                  _vm.$store.state.vehicle.globalPlatFormArr,
                                height: "45vh",
                                "cell-class-name": _vm.tableCellClassNamePlat
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "60",
                                  label: "序号"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "type", label: "指令" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "alarmName" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.type === 37889
                                                    ? "督办"
                                                    : "查岗"
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1512150790
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "",
                                  label: "内容",
                                  width: "340",
                                  "show-overflow-tooltip": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.type === 37889
                                            ? _c(
                                                "span",
                                                { staticClass: "alarmName" },
                                                [
                                                  _vm._v(
                                                    "应上级平台报警督办，请及时处理" +
                                                      _vm._s(
                                                        scope.row.vehicleNo
                                                      ) +
                                                      "的报警"
                                                  )
                                                ]
                                              )
                                            : scope.row.dataType === "0xEC"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.infoContent)
                                                )
                                              ])
                                            : scope.row.dataType === "0xF3"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("filterJson")(
                                                      scope.row.infoContent
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  671739966
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "warnTime",
                                  label: "报警时间",
                                  width: "160",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "upgradeRemind", label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-platform",
                                              on: {
                                                click: function($event) {
                                                  return _vm.openDealDia(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("处理")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1060333146
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "过期驾驶员", name: "driverOut" } },
                        [
                          _c("driver-expire", {
                            attrs: { drivingTypeObj: _vm.drivingTypeObj }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "过期车辆", name: "carOut" } },
                        [
                          _c("car-expire", {
                            attrs: {
                              licensePlateColorObj: _vm.licensePlateColorObj,
                              typeObj: _vm.typeCarObj
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "平台抽查",
                            name: "platformCheckName"
                          }
                        },
                        [
                          _vm.messageActiveName == "platformCheckName"
                            ? _c("platform-check")
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.$route.path.indexOf("projectBus") > -1
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "方案到期", name: "planOut" } },
                            [
                              _vm.messageActiveName == "planOut"
                                ? _c("plan-out")
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.dialogMessageVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialogMessageVisible = false
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "报警详情", visible: _vm.detailDialog, width: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.detailDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "body" },
            [
              _vm.detailDialog
                ? _c("alarm-dialog", {
                    ref: "alarmDia",
                    attrs: {
                      detailData: _vm.alarmItem,
                      typeObj: _vm.typeObj,
                      alarmLevelObj: _vm.alarmLevelObj,
                      alarmSourceObj: _vm.alarmSourceObj,
                      disposeWayList: _vm.disposeWayList,
                      alarmConfirmList: _vm.alarmConfirmList,
                      disposeTypeObj: _vm.disposeTypeObj,
                      disposeWayObj: _vm.disposeWayObj
                    },
                    on: { cancelDialog: _vm.cancelDialog }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "default" },
                  on: {
                    click: function($event) {
                      _vm.detailDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.OnInspectionVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "undealMsg",
              attrs: {
                title: _vm.diaTitle,
                visible: _vm.OnInspectionVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.OnInspectionVisible = $event
                },
                close: function() {
                  return (_vm.OnInspectionVisible = false)
                }
              }
            },
            [
              _c("div", { staticClass: "search-area" }, [
                _c("div", { staticClass: "dialog-area" }, [
                  _c("div", { staticClass: "dialog-header" }, [
                    _vm._v("问题如下")
                  ]),
                  _c("div", { staticClass: "dialog-body" }, [
                    _vm.superiorPlatformData.infoContent &&
                    (_vm.superiorPlatformData.infoContent.Type == "验证码" ||
                      _vm.superiorPlatformData.infoContent.Type == 1)
                      ? _c("img", {
                          staticStyle: { "text-align": "center" },
                          attrs: {
                            src:
                              "data:image/png;base64," +
                              _vm.superiorPlatformData.infoContent.Content,
                            alt: ""
                          }
                        })
                      : _c("div", [
                          _vm.superiorPlatformData.dataType === "0xF3"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.superiorPlatformData.infoContent.Content
                                  )
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.superiorPlatformData.infoContent)
                                )
                              ])
                        ])
                  ]),
                  _c(
                    "p",
                    { staticClass: "dialog-textarea" },
                    [
                      _c("span", [_vm._v("回答：")]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "30",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.textareaInspection,
                          callback: function($$v) {
                            _vm.textareaInspection = $$v
                          },
                          expression: "textareaInspection"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function() {
                          return (_vm.OnInspectionVisible = false)
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submit(1)
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.overseeVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "undealMsg",
              attrs: {
                title: "报警督办",
                visible: _vm.overseeVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.overseeVisible = $event
                },
                close: function() {
                  return (_vm.overseeVisible = false)
                }
              }
            },
            [
              _c("div", { staticClass: "search-area" }, [
                _c("div", { staticClass: "dialog-area" }, [
                  _c("div", { staticClass: "dialog-header" }, [
                    _vm._v("督办信息")
                  ]),
                  _c("div", { staticClass: "dialog-body" }, [
                    _vm._v(
                      " 应上级平台报警督办，请及时处理" +
                        _vm._s(_vm.superiorPlatformData.vehicleNo) +
                        "的报警 "
                    )
                  ]),
                  _c(
                    "p",
                    { staticClass: "dialog-textarea" },
                    [
                      _c("span", [_vm._v("处理：")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择处理类型"
                          },
                          model: {
                            value: _vm.result,
                            callback: function($$v) {
                              _vm.result = $$v
                            },
                            expression: "result"
                          }
                        },
                        _vm._l(_vm.modelList, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function() {
                          return (_vm.overseeVisible = false)
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submit(5)
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }