<template>
  <el-container class="homePage">
    <el-aside
      v-show="!isqiankun"
      :width="isCollapse ? '60px' : '240px'"
      style="background: #242733"
    >
      <side-bar />
    </el-aside>
    <el-container>
      <el-header class="el-header" v-show="!isqiankun">
        <my-header />
      </el-header>
      <el-main id="el-main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import sideBar from '@/views/layout/SideBar.vue'
import myHeader from '@/views/layout/Header.vue'
export default {
  data() {
    return {
      img: '',
      isCollapse: false,
      isqiankun:window.__POWERED_BY_QIANKUN__
    }
  },
  components: {
    sideBar,
    myHeader
  },
  created() {
    //事件订阅
    this.$bus.$on('isCollapse', (res) => {
      this.isCollapse = res
    })
  },
  beforeDestroy() {
    this.$bus.$off('isCollapse')
  }
}
</script>

<style lang="scss" scoped>
.homePage {
  @include themify() {
    .el-main {
      overflow: hidden;
      background: themed('n1');
    }
  }
}
</style>
