var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "homePage" },
    [
      _c(
        "el-aside",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isqiankun,
              expression: "!isqiankun"
            }
          ],
          staticStyle: { background: "#242733" },
          attrs: { width: _vm.isCollapse ? "60px" : "240px" }
        },
        [_c("side-bar")],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isqiankun,
                  expression: "!isqiankun"
                }
              ],
              staticClass: "el-header"
            },
            [_c("my-header")],
            1
          ),
          _c("el-main", { attrs: { id: "el-main" } }, [_c("router-view")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }